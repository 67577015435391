import { LottieOptions, useLottie } from 'lottie-react';

import duckAnim from '../assets/lotties/duck.json';
import loadingAnim from '../assets/lotties/loading.json';
import duckSleepingAnim from '../assets/lotties/sleepingduck.json';

const lottieAnims = {
	loading: loadingAnim,
	duck: duckAnim,
	sleepingDuck: duckSleepingAnim,
};
export type LottieAnim = keyof typeof lottieAnims;
interface LottieComponentProps extends Partial<LottieOptions> {
	lottieAnim: LottieAnim;
}

export const LottieComponent: React.FC<LottieComponentProps> = (props) => {
	const options: LottieOptions = {
		...props,
		animationData: lottieAnims[props.lottieAnim],
		loop: true,
	};

	const { View } = useLottie(options, { width: props.width, height: props.height });

	return View;
};
