import styled from 'styled-components';

export const Button = styled.a`
	border-radius: 3rem;
	background-color: ${({ theme }) => theme.colors.primary};
	padding: 0.8rem 3rem;
	border: 1rem;
	color: ${({ theme }) => theme.colors.white};
	font-size: 1.1rem;
	font-weight: 400;
	text-decoration: none;

	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
		background-color: ${({ theme }) => theme.colors.white};
	}
`;
