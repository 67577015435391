// eslint-disable-next-line import/no-unresolved
import { CloudStreamAPI } from '@zerolight-core/libzl/types/src/globals';
import {
	CloudStream,
	CloudStreamConnectionState,
	CloudStreamContainer,
	useCloudStreamInstance,
	useCloudStreamState,
} from '@zerolight-core/libzl-react';

import {
	HTMLAttributes,
	PropsWithChildren,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import styled from 'styled-components';

import {
	defaultIdleTimeout,
	OptionalStreamOpts,
	useCloudStreamOptions,
} from '../CloudStreamOptionsProvider';

import { Button } from '../components/Button';
import { LottieAnim, LottieComponent } from '../components/Lottie';

import { StreamController } from '../components/StreamControls';
import { H1, H3, Paragraph } from '../components/Text';
import { useToggleStreamMute } from '../hooks/useToggleStreamMute';
import { ErrorType, setError, setStreamSettings, setStreamState } from '../reducer/streamSlice';
import { RootState } from '../store';

const StreamPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	position: relative;
`;

const StreamStatusContainer = styled.div<{ $visible: boolean }>`
	display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const StreamErrorContainer = styled.div`
	display: flex;
	padding: 3rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.secondary};
	gap: 1rem;
`;

const StreamErrorMainContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
`;

const FigureStream = styled.figure<{ $visible: boolean }>`
	display: ${({ $visible }) => ($visible ? 'inline-block' : 'none')};
	width: 100vw;
	height: 100vh;
	margin: auto;
	box-sizing: content-box;
`;

interface FigureProps extends HTMLAttributes<HTMLElement> {
	$visible: boolean;
	ref: React.MutableRefObject<null>;
}

// eslint-disable-next-line react/display-name
const Figure = forwardRef<HTMLElement, PropsWithChildren<FigureProps>>(
	({ children, style, ...props }, ref) => {
		const streamState = useCloudStreamState();

		const dispatch = useDispatch();
		const streamConnectionState = CloudStreamConnectionState[streamState];

		useEffect(() => {
			dispatch(setStreamState({ streamState: streamConnectionState }));
		}, [streamConnectionState, dispatch]);

		const isVisible = streamConnectionState === 'Ready';

		return (
			<FigureStream {...props} $visible={isVisible} ref={ref}>
				{children}
			</FigureStream>
		);
	},
);

export const ErrorComponents: React.FC<{ error: { type: ErrorType | null } }> = ({ error }) => {
	let errorTitle = 'Hmm Something went wrong...';
	let lottieAnim: LottieAnim = 'duck';
	let buttonTitle = 'Portal help-desk';
	let errorMsg = 'Seems like something went wrong please try again...';
	let link = 'https://support.zerolight.com/';

	const location = useLocation();

	switch (error.type) {
		case 'timeout': {
			errorTitle = 'Your stream has timed out';
			lottieAnim = 'sleepingDuck';
			buttonTitle = 'refresh';

			errorMsg =
				'This link may have timed out or have an error. Try refreshing the page or reaching out to the creator. ';

			link = location.pathname;
			break;
		}
		case 'brokenLink': {
			errorTitle = 'Hmm, we can’t find that link';
			lottieAnim = 'duck';
			buttonTitle = 'Portal help-desk';

			errorMsg =
				'This link may have been deleted, or may not be available. Try reaching out to the creator. ';

			break;
		}
		case 'streamError': {
			errorTitle = 'Hmm, something went wrong';
			lottieAnim = 'duck';
			buttonTitle = 'Portal help-desk';

			errorMsg =
				'Seems like something went wrong. Try refreshing the page or reaching out to the creator.';
		}
	}

	return (
		<StreamErrorMainContainer>
			<StreamErrorContainer>
				<LottieComponent lottieAnim={lottieAnim} width="250px" />
				<H1 style={{ textAlign: 'center' }}>{errorTitle}</H1>
				<Paragraph style={{ textAlign: 'center', paddingBottom: '1rem' }}>
					{errorMsg}
				</Paragraph>
				<Button target="_blank" rel="noopener noreferrer" href={link}>
					{buttonTitle}
				</Button>
			</StreamErrorContainer>
		</StreamErrorMainContainer>
	);
};

export const StreamPage: React.FC = () => {
	const streamState = useSelector((state: RootState) => state.stream.streamState);
	const { options, ready, updateStreamOptions } = useCloudStreamOptions();
	const [searchParams, _] = useSearchParams();
	const cloudStream = useCloudStreamInstance() as CloudStreamAPI;

	const [initialAudioSetup, setInitialAudioSetup] = useState(false);

	const dispatch = useDispatch();

	const disableRightClick = (event: MouseEvent) => {
		event.preventDefault();
	};

	useEffect(() => {
		const customer = searchParams.get('customer');
		const renderService = searchParams.get('service');
		if (!customer || !renderService) {
			dispatch(setError({ type: 'brokenLink' }));

			// NOTE: TEMP until officially out
			// eslint-disable-next-line no-console
			console.warn(`
			Valid URL params:
			customer - Customer name given to you by ZeroLight when you signed up for OmniStream - required when connecting to a live OmniStream service
			service - Service name given to you by ZeroLight when you signed up for OmniStream - required when connecting to a live OmniStream service
			appname - App name given to you by ZeroLight - not required, used to distinguish between apps on services that run multiple apps
			appid - App ID given to you by ZeroLight - not required, used to distinguish between apps on services that run multiple apps
			showcontrols - Pass "showcontrols=true" to show stream controls, leave empty to hide them
			pointerlock - Pass "pointerlock=true" to lock the mouse cursor when in fullscreen, leave empty to leave the mouse cursor as standard
			fakemousewithtouch - Pass "fakemousewithtouch=true" to show a fake mouse with touch
			autostartaudio - Pass "autostartaudio=true" to umnute the stream on the first click on the stream window
			consumeclicks - When set to true OmniStream will consume all mouse events and prevent browser default actions
			idledisconnecttime - Time to disconnect after user is idle, in milliseconds - defaults to 15 minutes
			`);
		} else {
			const appName = searchParams.get('appname');
			const appId = searchParams.get('appid');
			const showControls = searchParams.get('showcontrols') === 'true';
			const consumeMouseClicks = searchParams.get('consumeclicks') === 'true';
			const fakeMouseWithTouch = searchParams.get('fakemousewithtouch') === 'true';

			const idleDisconnectTime = searchParams.has('idledisconnecttime')
				? parseInt(searchParams.get('idledisconnecttime') || defaultIdleTimeout.toString())
				: defaultIdleTimeout;

			if (showControls) {
				dispatch(setStreamSettings({ showControls: true }));
			}

			const updatedStreamOptions: OptionalStreamOpts = {
				consumeMouseClicks,
				fakeMouseWithTouch,
				idleDisconnectTime,
				cloudConnectionParameters: { customer, renderService },
			};

			if (consumeMouseClicks) {
				const component = document.getElementById('streamContainer');
				if (component) {
					component.addEventListener('contextmenu', disableRightClick);
				}
			}

			if (appName && appId) {
				updatedStreamOptions.appSelect = {
					project: 'omnistream',
					app: appName,
					id: appId,
				};
			}

			updateStreamOptions(updatedStreamOptions);
		}

		return () => {
			document.removeEventListener('contextmenu', disableRightClick);
		};
	}, [searchParams, dispatch]);

	const ref = useRef<HTMLElement | null>(null);

	const [__, handleStreamMuteToggle] = useToggleStreamMute();
	const onStreamClick = useCallback(() => {
		const autoStartAudio = searchParams.get('autostartaudio') === 'true';
		const pointerLockParam = searchParams.get('pointerlock') === 'true';
		if (!cloudStream) return;
		if (autoStartAudio && !initialAudioSetup) {
			handleStreamMuteToggle(cloudStream, true);
			dispatch(setStreamSettings({ volume: 0.5 }));
			setInitialAudioSetup(true);
		}

		if (pointerLockParam) {
			cloudStream.setPointerLockState(true);
		}
	}, [cloudStream, dispatch, handleStreamMuteToggle, initialAudioSetup, searchParams]);

	useEffect(() => {
		ref.current = document.querySelector('#streamContainer');
		ref.current?.addEventListener('click', onStreamClick, true);
	}, [onStreamClick]);

	useEffect(() => {
		cloudStream?.addEventListener('error', (err: Error) => {
			// eslint-disable-next-line no-console
			console.error('OmniStream had an error:', err);
			dispatch(setError({ type: 'streamError' }));
		});

		return () => {
			cloudStream?.removeEventListener('error', () => null);
		};
	}, [cloudStream, dispatch]);

	const error = useSelector((state: RootState) => state.stream.error);

	const isStreamReady = streamState === 'Ready' && !error.type;

	if (error.type) {
		return <ErrorComponents error={error} />;
	}

	return (
		<>
			<StreamPageContainer>
				<StreamStatusContainer $visible={!isStreamReady}>
					<LottieComponent width={250} lottieAnim="loading" />
					<H3>{streamState}...</H3>
				</StreamStatusContainer>
				<CloudStream cloudstream={cloudStream} options={options} connect={ready}>
					<Figure $visible={isStreamReady}>
						<CloudStreamContainer
							style={{ width: '100%', height: '100%' }}
							id={'streamContainer'}
						/>
					</Figure>
				</CloudStream>
				{isStreamReady && (
					<StreamController streamContainer={ref} cloudStream={cloudStream} />
				)}
			</StreamPageContainer>
		</>
	);
};
