import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorType = 'brokenLink' | 'timeout' | 'streamError';

export interface StreamSlice {
	loading: boolean;
	error: {
		type: ErrorType | null;
	};
	streamState: string;
	settings: {
		audioEnabled: boolean;
		showControls: boolean;
		volume: number;
	};
}
export type loadingPayload = {
	loading: boolean;
};

export type errorPayload = {
	type: ErrorType;
};
export type settingsPayload = {
	audioEnabled?: boolean;
	showControls?: boolean;
	volume?: number;
};

export type streamStatePayload = {
	streamState: string;
};

const initialState: StreamSlice = {
	loading: false,
	error: {
		type: null,
	},
	streamState: 'Disconnected',
	settings: {
		audioEnabled: false,
		showControls: false,
		volume: 0.5,
	},
};

export const streamSlice = createSlice({
	name: 'stream',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<loadingPayload>) => {
			state.loading = action.payload.loading;
		},
		setError: (state, action: PayloadAction<errorPayload>) => {
			state.error = action.payload;
		},
		setStreamSettings: (state, action: PayloadAction<settingsPayload>) => {
			state.settings = { ...state.settings, ...action.payload };
		},
		setStreamState: (state, action: PayloadAction<streamStatePayload>) => {
			state.streamState = action.payload.streamState;
		},
	},
});

export const { setLoading, setError, setStreamSettings, setStreamState } = streamSlice.actions;

export const streamReducer = streamSlice.reducer;
