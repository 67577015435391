// eslint-disable-next-line import/no-unresolved
import { CloudStreamAPI } from '@zerolight-core/libzl/types/src/globals';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setStreamSettings } from '../reducer/streamSlice';
import { RootState } from '../store';

export const useToggleStreamMute = (): [
	boolean,
	(cloudStream: CloudStreamAPI, muteStatus?: boolean) => void,
] => {
	const audioEnabledState = useSelector((state: RootState) => state.stream.settings.audioEnabled);
	const dispatch = useDispatch();
	const handleStreamToggle = useCallback(
		(cloudStream: CloudStreamAPI, muteStatus?: boolean) => {
			if (typeof muteStatus === 'boolean') {
				dispatch(setStreamSettings({ audioEnabled: muteStatus }));
				cloudStream.setAudioState(muteStatus, { stereo: true });
			} else {
				dispatch(setStreamSettings({ audioEnabled: !audioEnabledState }));
				cloudStream.setAudioState(!audioEnabledState, { stereo: true });
			}
		},
		[dispatch, audioEnabledState],
	);

	return [audioEnabledState, handleStreamToggle];
};
