import { LibZLInstanceProvider, CloudStreamInstanceProvider } from '@zerolight-core/libzl-react';

import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { CloudStreamOptionsProvider } from './CloudStreamOptionsProvider';

import { router } from './utils/routerSetup';
import { theme } from './utils/theme';

type ProjectNames = 'omnistream2.0';

interface AppProps {
	projectName: ProjectNames;
}

export const App = ({ projectName }: AppProps) => {
	let version;
	const searchParams = new URLSearchParams(document.location.search);
	if (searchParams.has('libzl')) {
		version = { version: searchParams.get('libzl') };
	}

	return (
		<ThemeProvider theme={theme}>
			<LibZLInstanceProvider src={version}>
				<CloudStreamOptionsProvider>
					<CloudStreamInstanceProvider projectName={projectName}>
						<RouterProvider router={router} />
					</CloudStreamInstanceProvider>
				</CloudStreamOptionsProvider>
			</LibZLInstanceProvider>
		</ThemeProvider>
	);
};
