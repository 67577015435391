import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { PageLayout } from '../layout/PageLayout';
import { StreamPage } from '../pages/Stream';

export const router = createBrowserRouter(
	createRoutesFromElements([
		<Route key="basePath" path="/" element={<PageLayout />}>
			<Route path="" element={<StreamPage />} />
		</Route>,
	]),
);
