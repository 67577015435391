import ReactDOM from 'react-dom/client';

import './index.css';
import { Provider } from 'react-redux';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<App projectName={'omnistream2.0'} />
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
