// eslint-disable-next-line import/no-unresolved
import { CloudStreamAPI } from '@zerolight-core/libzl/types/src/globals';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from './Icon';

import { Volume } from './Volume';
import { RootState } from '../store';

const StreamContainerController = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	justify-content: end;
	color: ${({ theme }) => theme.colors.white};
	flex: 1;
	position: fixed;
	bottom: 3.5%;
	left: 3.5%;
`;

const StreamControlIcon = styled(Icon)`
	flex: 1;
`;

const StreamOptionsList = styled.ul`
	display: flex;
	flex-direction: row;
	gap: 0.4rem;
	list-style-type: none;

	padding: 0;
	margin: 0;
`;

const StreamControlButton = styled.button<{ $active?: boolean }>`
	display: flex;
	padding: 6px 10px;
	place-items: center;
	border-radius: 3px;
	border: none;
	height: 50px;
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.secondary};

	&:hover {
		background-color: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.white};
	}

	${({ $active, theme }) =>
		$active &&
		`
		background-color: ${theme.colors.primary};
		color: ${theme.colors.white};

	`}
`;

const StreamControlListItem = styled.li`
	display: flex;
	place-items: center;
`;

export const StreamController: React.FC<{
	cloudStream: CloudStreamAPI;
	streamContainer: RefObject<HTMLElement>;
}> = ({ cloudStream, streamContainer }) => {
	const loading = useSelector((state: RootState) => state.stream.loading);
	const [searchParams, _] = useSearchParams();
	const showControls = useSelector((state: RootState) => state.stream.settings.showControls);

	useEffect(() => {
		const pointerLock = searchParams.get('pointerlock');

		if (pointerLock === 'true') {
			cloudStream.setPointerLockState(true);
		} else {
			cloudStream.setPointerLockState(false);
		}
	}, [cloudStream, searchParams]);

	const [visible, setVisible] = useState(false);

	const isFullScreen = () => {
		return document.fullscreenElement;
	};

	const handleSettingsClick = useCallback(() => {
		setVisible(!visible);
	}, [visible]);

	const handleFullScreen = useCallback(() => {
		if (isFullScreen()) {
			document.exitFullscreen();
			cloudStream.setFullscreenState(true);
		} else {
			streamContainer?.current?.requestFullscreen();
			cloudStream.setFullscreenState(false);
		}
	}, [cloudStream, streamContainer]);

	if (loading || !showControls) {
		return;
	}

	return (
		<StreamContainerController>
			<StreamControlButton $active={visible} onClick={handleSettingsClick}>
				<StreamControlIcon icon="gear" size={32} />
			</StreamControlButton>
			{visible && (
				<StreamOptionsList>
					<StreamControlListItem>
						<Volume cloudStream={cloudStream} />
					</StreamControlListItem>
					<StreamControlListItem key={`stream-control-fullscreen`}>
						<StreamControlButton onClick={handleFullScreen}>
							<StreamControlIcon icon="screen" size={32} />
						</StreamControlButton>
					</StreamControlListItem>
				</StreamOptionsList>
			)}
		</StreamContainerController>
	);
};
