export const theme = {
	colors: {
		primary: '#2F53F1',
		secondary: '#03083D',
		background: '#010212',
		white: '#ffffff',
	},
};

export type ThemeType = typeof theme;
