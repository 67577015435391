import styled from 'styled-components';

export const H1 = styled.h1`
	color: ${({ theme }) => theme.colors.white};
`;
export const H2 = styled.h2`
	color: ${({ theme }) => theme.colors.white};
`;
export const H3 = styled.h3`
	color: ${({ theme }) => theme.colors.white};
`;
export const H4 = styled.h4`
	color: ${({ theme }) => theme.colors.white};
`;
export const H5 = styled.h5`
	color: ${({ theme }) => theme.colors.white};
`;
export const span = styled.span`
	color: ${({ theme }) => theme.colors.white};
`;
export const Paragraph = styled.p`
	color: ${({ theme }) => theme.colors.white};
`;
