import { GoScreenFull } from 'react-icons/go';
import { IconBaseProps } from 'react-icons/lib';
import { PiSpeakerSimpleX, PiSpeakerSimpleHigh, PiGearSix } from 'react-icons/pi';

const icons = {
	speakerMute: PiSpeakerSimpleX,
	speaker: PiSpeakerSimpleHigh,
	mute: PiSpeakerSimpleX,
	screen: GoScreenFull,
	gear: PiGearSix,
};
export type IconType = keyof typeof icons;

interface IconsProps extends IconBaseProps {
	icon: IconType;
}

export const Icon = ({ icon, ...props }: IconsProps) => {
	return icons[icon](props);
};
