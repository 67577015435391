import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Page = styled.div`
	display: grid;
	height: 100svh;
`;

const PageMain = styled.main`
	background-color: ${({ theme }) => theme.colors.background};
`;

export const PageLayout: React.FC = () => {
	return (
		<Page>
			<PageMain>
				<Outlet />
			</PageMain>
		</Page>
	);
};
