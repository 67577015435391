import { configureStore, PreloadedState } from '@reduxjs/toolkit';

import { streamReducer } from './reducer/streamSlice';

export const reducer = {
	stream: streamReducer,
};

export const store = configureStore({
	reducer,
});

export type RootState = ReturnType<typeof store.getState>;

export function setupStore(preloadedState?: PreloadedState<RootState>) {
	return configureStore({
		reducer,
		preloadedState,
	});
}

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = ReturnType<typeof store.dispatch>;
